import React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Button } from '@mui/material';
import LogoImage from '../logo.jpeg';
import LogoutIcon from '@mui/icons-material/Logout';
import MainListItems from '../components/listItems';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://skaletek.com/">
        Skaletek
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 150;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  marginLeft: drawerWidth,
  width: `calc(100% - 72px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


const defaultTheme = createTheme();

const Dashboard = ({
  user
}) => {
  const [open, setOpen] = React.useState(true);


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: "#DEDDDB",
              color: "#000"
            }}
          >


            <Typography
              /* component="h6"
              variant="h6" */
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Welcome {user.user.attributes.name ?? ''}
            </Typography>
            <LogoutIcon color='error' onClick={user.signOut} />

            <Button variant='inherit' onClick={user.signOut} sx={{ flexDirection: 'column', px: [1] }}>
              Log Out
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <img src={LogoImage} alt="Logo" width="100%" height={open ? '100' : '10%'} />

          <Divider sx={{ my: 1 }} />
          <List component="nav">
            <MainListItems open={open} setOpen={setOpen} />

          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 }}>

            <iframe title="Report Section" width="1024" height="1060" src="https://app.powerbi.com/view?r=eyJrIjoiMzk2MWVmNDAtMzE5NS00OGM0LWJmNzctNmY4YzlkZTI2ZTM4IiwidCI6IjMyOTc3ZjJhLTU0ODEtNDc1OC05MjViLTI1YjlmZmM1NWFkNCIsImMiOjN9&pageName=ReportSection502a1222cde7e5742114" frameborder="0" allowFullScreen="true"></iframe>

            <Copyright sx={{ pt: 4 }} />

          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;